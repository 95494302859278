
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { NextPage } from 'next';
import { ErrorPageContent } from 'components/organisms/ErrorPageContent';
import { DEFAULT_DOMAIN } from 'prismic/config';

const Error: NextPage = () => {
  return <ErrorPageContent />;
};

 async function _getServerSideProps({ query }: any) {
  return {
    props: {
      domain: query.domain || DEFAULT_DOMAIN,
      documentRelations: {},
      sharedData: {},
      guideData: {},
    },
  };
}
export default Error;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/_error',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  